import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsDialog } from 'components';
import MarketForm from './MarketForm';
import { PostNewTask } from 'services';
import { toast } from 'react-toastify';
import { IMarketTask, ITaskType } from 'models';
import { queryClient } from 'index';
import dayjs from 'dayjs';
import { GlobalContext } from 'context';

export interface NotificationDialogProps {
    open: boolean;
    onClose?: () => void;
    onOpen?: () => void;
}

export const PublishOfferDialog = (props: NotificationDialogProps) => {
    const marketFormRef = useRef<any>(null);

    const { onClose, open, onOpen } = props;
    const { taskTypeListQuery } = useContext(GlobalContext);

    const { t } = useTranslation();

    const handleClose = () => {
        onClose && onClose();
    };

    const handleAccept = () => {
        marketFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: any) => {
        const data2 = {
            ...data,
            storage: data.storage ? Number(data.storage) : null,
            date_from: dayjs(data.date_from).format('YYYY-MM-DD'),
            date_to: dayjs(data.date_to).format('YYYY-MM-DD')
        } as IMarketTask;

        const task_code = taskTypeListQuery.data
            ?.filter((el: ITaskType) => Number(el.id) === Number(data?.task_type))
            .map(el => el.code)[0];

        PostNewTask(data2, task_code)
            .then(res => {
                toast.success(t('Save Successful'));
            })
            .catch(err => {
                toast.error(`${t('Save Failed')}: ${err.message}`);
            })
            .finally(() => {
                handleClose();
                queryClient.invalidateQueries({ queryKey: ['tasks'] });
            });
    };

    return (
        <DetailsDialog
            open={open}
            onClose={onClose && handleClose}
            onOpen={onOpen}
            onAccept={handleAccept}
            buttonOKtitle={t('buttons.SaveButtonLabel')}
            title={t('marketPage.PublishOfferDialog.DialogTitle')}>
            <MarketForm ref={marketFormRef} onSubmit={onSubmit}></MarketForm>
        </DetailsDialog>
    );
};
