import React, { useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsDialog } from 'components';
import StorageForm from './StorageForm';
import { PostNewAdminStorage, PostNewStorage } from 'services';
import { toast } from 'react-toastify';
import { IStorageInput } from 'models';
import { UserContext } from 'context';
import { queryClient } from 'index';

export interface NotificationDialogProps {
    open: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    isAdmin?: boolean;
}

export const StorageAddDialog = (props: NotificationDialogProps) => {
    const storageFormRef = useRef<any>(null);
    const { selectedUserId } = useContext(UserContext);

    const { onClose, open, onOpen, isAdmin } = props;

    const { t } = useTranslation();

    const handleClose = () => {
        onClose && onClose();
    };

    const submitfunc = isAdmin ? PostNewAdminStorage : PostNewStorage;

    const handleAccept = () => {
        storageFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: IStorageInput) => {
        submitfunc(data, selectedUserId)
            .then(res => {
                toast.success(t('Save Successful'));
            })
            .catch(err => {
                toast.error(`${t('Save Failed')}: ${err.message}`);
            })
            .finally(() => {
                handleClose();
                queryClient.invalidateQueries(['user_storages']);
            });
    };

    return (
        <DetailsDialog
            open={open}
            onClose={onClose && handleClose}
            onOpen={onOpen}
            onAccept={handleAccept}
            buttonOKtitle={t('buttons.SaveButtonLabel')}
            title={t('userStorage.PublishStorageDialog.DialogTitle')}>
            <StorageForm ref={storageFormRef} onSubmit={onSubmit}></StorageForm>
        </DetailsDialog>
    );
};
