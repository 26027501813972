import {
    ICargoType,
    ITaskStatus,
    ITaskTypeFull,
    IMarketTask,
    IMarketTaskFull,
    ITransportationTaskGrid as IMArketTaskGrid
} from 'models';
import {
    GET_SHIPPING_TASKS,
    GET_STORAGE_TASKS,
    GET_TASK_CARGO_TYPES,
    GET_TASK_STATUSES,
    GET_TASK_TYPES,
    GET_TRANSPORTATION_TASKS
} from 'parameters';
import privateAxios from 'services/global/privateAxios.service';

export const GetTasks = ({ url }: { url: string }): Promise<Array<IMArketTaskGrid>> => {
    let taskList = privateAxios
        .get(url)
        .then(res => {
            let responseData: Array<IMarketTaskFull> = res.data;
            let gridData: Array<IMArketTaskGrid> = [];
            if (responseData !== null && responseData !== undefined) {
                for (let index = 0; index < responseData.length; index++) {
                    const element = responseData[index] as IMarketTaskFull;
                    gridData.push({
                        id: element.id,
                        location_from: element.location_from,
                        location_to: element.location_to,
                        task_type: element.task_type,
                        task_status: element.task_status,
                        date_from: element?.date_from,
                        date_to: element?.date_to,
                        storage: element?.storage,
                        city: element?.city,
                        vehicle: element?.vehicle
                    });
                }
            }
            return gridData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch user');
        });

    return taskList;
};

export const GetSingleTask = ({ url }: { url?: string }): Promise<IMarketTaskFull> => {
    let task = privateAxios
        .get(`${url}`)
        .then(res => {
            let responseData: IMarketTaskFull = res.data;
            if (responseData !== null && responseData !== undefined) {
                return responseData;
            } else {
                throw new Error('no data in fetch task');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch task');
        });

    return task;
};

export const GetTaskTypes = (): Promise<Array<ITaskTypeFull>> => {
    let task = privateAxios
        .get(GET_TASK_TYPES)
        .then(res => {
            let responseData: Array<ITaskTypeFull> = res.data;
            if (responseData !== null && responseData !== undefined) {
                return responseData;
            } else {
                throw new Error('no data in fetch task types');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch task types');
        });

    return task;
};

export const GetTaskStatuses = (): Promise<Array<ITaskStatus>> => {
    let task = privateAxios
        .get(GET_TASK_STATUSES)
        .then(res => {
            let responseData: Array<ITaskStatus> = res.data;
            if (responseData !== null && responseData !== undefined) {
                return responseData;
            } else {
                throw new Error('no data in fetch task statuses');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch task statuses');
        });

    return task;
};

export const GetCargoTypes = (): Promise<Array<ICargoType>> => {
    let task = privateAxios
        .get(GET_TASK_CARGO_TYPES)
        .then(res => {
            let responseData: Array<ICargoType> = res.data;
            if (responseData !== null && responseData !== undefined) {
                return responseData;
            } else {
                throw new Error('no data in fetch cargo types');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch cargo types');
        });

    return task;
};

export const PostNewTask = (offerDetails: IMarketTask, task_code?: string): Promise<IMarketTask> => {
    let base;

    // {id: 1, name: 'Shipping', code: 'shipping', active: true}
    // {id: 2, name: 'Transportation', code: 'transportation', active: true}
    // {id: 3, name: 'Storage', code: 'storage', active: true}

    switch (task_code) {
        case 'shipping':
            base = GET_SHIPPING_TASKS;
            break;

        case 'transportation':
            base = GET_TRANSPORTATION_TASKS;
            break;

        case 'storage':
            base = GET_STORAGE_TASKS;
            break;

        default:
            throw new Error('missing task type in reqest');
    }

    let vehicle = privateAxios
        .post(base, offerDetails)
        .then(res => {
            let responseData: IMarketTask = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to add task');
        });

    return vehicle;
};

export const AcceptTask = ({ id, task_code }: { id?: number; task_code?: string }): Promise<IMarketTask> => {
    let base;

    // {id: 1, name: 'Shipping', code: 'shipping', active: true}
    // {id: 2, name: 'Transportation', code: 'transportation', active: true}
    // {id: 3, name: 'Storage', code: 'storage', active: true}

    switch (task_code) {
        case 'shipping':
            base = GET_SHIPPING_TASKS + id + '/accept_task/';
            break;

        case 'transportation':
            base = GET_TRANSPORTATION_TASKS + id + '/accept_task/';
            break;

        case 'storage':
            base = GET_STORAGE_TASKS + id + '/accept_task/';
            break;

        default:
            throw new Error('missing task type in reqest');
    }

    let task = privateAxios
        .put(base)
        .then(res => {
            let responseData: IMarketTask = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to accept task');
        });

    return task;
};

export const PatchTask = ({
    taskData,
    id,
    url
}: {
    taskData: IMarketTaskFull;
    id: string;
    url: string;
}): Promise<IMarketTaskFull> => {
    let task = privateAxios
        .put(`${url}${id}/`, taskData)
        .then(res => {
            let responseData: IMarketTaskFull = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to edit task');
        });

    return task;
};

export const DeleteTask = ({ id, url }: { id: string; url: string }): Promise<IMarketTaskFull> => {
    let task = privateAxios
        .delete(`${url}${id}/`)
        .then(res => {
            let responseData: IMarketTaskFull = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to delete task');
        });

    return task;
};

export const CancelTask = ({ id, url }: { id: string; url: string }): Promise<IMarketTaskFull> => {
    let task = privateAxios
        .put(`${url}${id}/cancel_task/`)
        .then(res => {
            let responseData: IMarketTaskFull = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to cancel task');
        });
    return task;
};

export const CompleteTask = ({ id, url }: { id: string; url: string }): Promise<IMarketTaskFull> => {
    let task = privateAxios
        .put(`${url}${id}/complete_task/`)
        .then(res => {
            let responseData: IMarketTaskFull = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to cancel task');
        });
    return task;
};

export const ReactivateTask = ({ id, url }: { id: string; url: string }): Promise<IMarketTaskFull> => {
    let task = privateAxios
        .put(`${url}${id}/cancel_task/`)
        .then(res => {
            let responseData: IMarketTaskFull = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to reactivate task');
        });
    return task;
};
